<template>

    <div>
        <Header />

        <SystemMessage />

        <main class="root-element">
            <Breadcrumbs />
            <PageTitle />

            <div class="root-element body-content ">

                <div class="columns">
                    <div class="content">
                        <h1>{{text.title_begin_password_reset}}</h1>
                        <p v-html="text.body_begin_password_reset"></p>

                        <form>
                            <Form v-model="formData" ref="form" />
                            <input type="submit" :title="text.request_reset_link" :value="text.request_reset_link" class="btn" @click="triggerReset($event)" @keyup.enter="triggerReset($event)">
                        </form>
                    </div>
                </div>
            </div>
        </main>

        <Footer />
    </div>

</template>

<script>
    import {triggerResetPassword } from "@/shared/dataservice";
    import {MESSAGE_TYPE_SUCCESS,MESSAGE_TYPE_ERROR} from "@/shared/consts";
    import {log} from "@/shared/utils";

    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import lang from "@/shared/lang";
    import Breadcrumbs from "@/components/breadcrumbs";
    import PageTitle from "@/components/pagetitle";
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import SystemMessage from '@/components/systemmessage';
    import Form from '@/components/form/form';

    export default {
        name: 'Maintenance',
        components: {Breadcrumbs, PageTitle, Header, Footer, SystemMessage, Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                formData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'email',
                        label: 'Email',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'email',
                            required: true,
                        },
                    },
                ]
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async triggerReset(e) {
                e.preventDefault();
                log(["Begin Password Reset"]);

                if(this.$refs.form.save()) {

                    let result = await triggerResetPassword({ emailaddress : this.getFormDataValueByName(this.formData, "email")});

                    if (result.status === 204) {
                        this.$store.dispatch('addSystemMessageAction', {
                            'type': MESSAGE_TYPE_SUCCESS,
                            'message': lang.message_begin_password_reset_success
                        });
                    } else {
                        this.$store.dispatch('addSystemMessageAction', {
                            'type': MESSAGE_TYPE_ERROR,
                            'message': lang.message_bad_server_response
                        });
                    }

                }
            }
        },
    }
</script>

<style lang="scss" scoped>


</style>
