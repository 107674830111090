<template>

    <div>
        <Header />

        <SystemMessage />

        <main class="root-element">
            <Breadcrumbs />
            <PageTitle />

            <div class="root-element body-content ">

                <div class="columns">
                    <div class="content" v-if="!resetComplete">
                        <h1>{{text.title_do_password_reset}}</h1>
                        <p v-html="text.body_do_password_reset"></p>

                        <form >
                            <Form v-model="formData" ref="form" />
                            <input type="submit" :title="text.reset_password" :value="text.reset_password" class="btn" @click="doPasswordReset($event)" @keyup.enter="doPasswordReset($event)">
                        </form>
                    </div>
                    <div class="content" v-else>
                        <h1>{{text.title_do_password_reset}}</h1>
                        <p v-html="text.body_do_password_reset_done"></p>

                        <a :href="returnURL" :title="text.login" class="btn" v-if="returnURL">{{text.login}}</a>
                        <router-link :to="{ name: 'Login' }" :title="text.login" class="btn" v-if="!returnURL">{{text.login}}</router-link>
                    </div>
                </div>
            </div>
        </main>

        <Footer />
    </div>

</template>

<script>
    import {doResetPassword} from "@/shared/dataservice";
    import {MESSAGE_TYPE_SUCCESS,MESSAGE_TYPE_ERROR} from "@/shared/consts";
    import {log} from "@/shared/utils";

    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import lang from "@/shared/lang";
    import Breadcrumbs from "@/components/breadcrumbs";
    import PageTitle from "@/components/pagetitle";
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import SystemMessage from '@/components/systemmessage';
    import Form from '@/components/form/form';

    export default {
        name: 'Maintenance',
        components: {Breadcrumbs, PageTitle, Header, Footer, SystemMessage, Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                resetComplete: false,
                formData: [
                    {
                        id: 1,
                        type: 'password',
                        name: 'password',
                        label: 'Password',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'password',
                            required: true,
                            min: 7,
                        },
                    },
                ]
            }
        },
        computed: {
            text() {
                return lang;
            },
            userID() {
                return this.$route.query.userId;
            },
            token() {
                return this.$route.query.token;
            },
            returnURL() {
                return this.$route.query.returnUrl ? this.$route.query.returnUrl : false;
            }
        },
        methods: {
            async doPasswordReset(e) {
                e.preventDefault();
                log(["Begin Password Reset"]);

                if(this.$refs.form.save()) {

                    let result = await doResetPassword(this.userID, { token : this.token, password : this.getFormDataValueByName(this.formData, "password")});

                    if (result.status === 204) {
                        this.resetComplete = true;
                        this.$store.dispatch('addSystemMessageAction', {
                            'type': MESSAGE_TYPE_SUCCESS,
                            'message': lang.message_begin_password_reset_success
                        });
                    } else {
                        this.$store.dispatch('addSystemMessageAction', {
                            'type': MESSAGE_TYPE_ERROR,
                            'message': lang.message_bad_server_response
                        });
                    }

                }
            }
        },
    }
</script>

<style lang="scss" scoped>


</style>
